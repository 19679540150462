.top_title{
    width:100%;
    background-color: #1ea0fa;
    height:40px;
}

.left_nav{
    width:200px;
    float: left;
}
.content{
    width:calc(100% - 200px);
    float: left;
    overflow: auto;
    /* background-color: antiquewhite; */
}
.login_root{
    width:300px;
    margin:0 auto;
    overflow: hidden;
}

.login_root h2{
    line-height: 40px;
    color: #1ea0fa;
    text-align: center;  
    margin-top: 50%; 
}
.login-form {
    max-width: 300px;
}
.login-form-button {
    width: 100%;
}