ul,li{
    list-style: none;
}
.button_box{
    width:100%;
    height: 50px;
    /* overflow: hidden; */
}
.refresh{
    height:30px;
    border:none;
    /* color:#1ea0fa; */
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    margin-right: 20px;
}
.refresh_icon{
    /* color:white; */
    font-size: 14px;
}
.left{
    float: left;
}
.right{
    float: right;
}
.input_title{
    width:80%;
    outline: none;
    border:1px solid #d9d9d9;
    height: 35px;
    line-height: 35px;
    text-indent: 10px;
}
.input_area{
    width:80%;
    outline: none;
}
.input_search{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 200px;
    height: 32px;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
}
.case_input{    
    outline: none;
    border: none;
    width:150px;
    height:30px;
    border-radius: 5px;
    border:1px solid #d9d9d9;
}
.insert_p{
    width: 33.3%;
    float: left;
}
.insert_p input{
    outline: none;
    /* border: none; */
    width:200px;
    height:30px;
    /* border-radius: 5px; */
    border:1px solid #d9d9d9;
    text-indent: 10px;
}
.span_h_p{
    width:50%;
    float: left;    
    position: relative;
    height:100px;

}
.span_h_p span{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}
textarea{
    outline: none;
    text-indent: 10px;
    border: none;
    border: 1px solid #d9d9d9;
}
.span_h_p textarea{
    position: absolute;
    width: 80%;
    top: 0;
    right: 15px;  
    outline: none;
    /* border: none;  
    border:1px solid #1ea0fa; */
    text-indent: 10px;
}
.men_box{
    width:700px;
    margin:50px auto;
    text-align: center;
}
.men_box .men_box_in{
    width:50%;
    float: left;
    height:150px;
    font-size: 16px;
}
.welcome{
    line-height: 120px;
    color: #1ea0fa;
    text-align: center;
}
.page_title{
    margin-top: 20px;
    color: #1ea0fa;
    text-align: center;   
}
.banner_ul{
    width:90%;
    margin:20px auto;
}
.banner_li{
    width:30%;
    margin:20px 1.66667%;
    float:left;
    position: relative;
    cursor: pointer;
}
.banner_li img{
    width:100%;
    text-align: center;
}
.banner_li .mask{
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    text-align: center;
    line-height: 130px;
    color:white;
    font-size: 30px;
}
.banner_li:hover .mask{
    display: block;
}
.query_err{
    text-align: center;
    line-height: 70px;
    font-size: 20px;
}
.btn_area{
    margin-top: 10px;
}
.main_width{
    min-width: 1300px;
}
.h4_mention{
    color: #1ea0fa;
    text-align: center;
}
.check_div{
    margin-top: 10px;
}
.check_div_span{
    font-weight: bold;
    /* color: #1ea0fa; */
}
.sub_btn{
    margin-top: 30px;
    width:100px;
}
.check_h3{
    margin-top: 30px;
}
.iphoneX{
    width: 342px;
    height: 680px;
    float: left;
    background-image: url(../img/iphone_uda.png);
    background-size: 100% 100%;
}
.iphone_uda{
    width: 342px;
    height: 680px;
    float: left;
    background-image: url(../img/iphone_uda.png);
    background-size: 100% 100%;
}
.iphoneBox{
    width: 770px;
    height: 690px;
}
.iphoneXin{
    height:560px;
    overflow:auto;
    margin-top:18px;    
}
.iphoneXin::-webkit-scrollbar {display:none}
.edit_div{
    position: relative;
    margin-bottom: 10px;
}
.edit_div_mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.2);

}
.edit_div:hover .edit_div_mask{
    display: block;
    overflow: hidden;
}
.edit_btn{
    margin-left:8px;
    margin-top: 4px;
}
.insert_box{
    width: 400px;
    /* height: 500px; */
    /* background-color: #d9d9d9; */
    float: right;
    margin-top: 15px;
}
.insert_box_div{
    margin-top: 20px;
}
.uda_title{
    overflow:hidden;
    white-space:no-wrap;
    text-overflow: ellipsis;
}
.panel_list{
    cursor: pointer;
    margin-bottom: 0;
    line-height: 50px;
    padding-left: 16px;
}
.panel_list:hover{
    background-color: rgba(30, 160, 250, 0.1);
}
.ant-collapse-content-box{
    padding:0!important;
}
.appendix{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-top:20;
}

